// import { getLodop } from "./LodopFuncs";
let LODOP = {};

var fun = {
  // 获取MAC地址
  getMAC: (acllBack) => {
    LODOP = getLodop();
    var TaskID1, TaskID2;
    TaskID1 = LODOP.GET_SYSTEM_INFO("NetworkAdapter.1.PhysicalAddress");
    TaskID2 = LODOP.GET_SYSTEM_INFO("NetworkAdapter.3.IPAddress");
    CLODOP.On_Return_Remain = true;
    CLODOP.On_Return = function (TaskID, Value) {
      if (TaskID == TaskID1) {
        console.log("Mac", Value);
        configs.printMachineMac = Value;
        acllBack(Value)
      }
      // if (TaskID == TaskID2) {
      //   console.log("IP", Value);
      //   configs.printMachineIp = Value;
      // }
    };
  },
  // 日期转换
  getTime: (time, type) => {
    var date = new Date(time);
    if (type == "-") {
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    } else {
      var Y = date.getFullYear() + "年";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "月";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日";
    }

    // let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    // let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    // let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    // return Y + M + D + h + m + s
    return Y + M + D;
  },
  // 资金转大写
  /** 数字金额大写转换(可以处理整数,小数,负数) */
  dealBigMoney: (n) => {
    var fraction = ["角", "分"];
    var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    var unit = [
      ["元", "万", "亿"],
      ["", "拾", "佰", "仟"],
    ];
    // var head = n < 0 ? '欠' : '';
    n = Math.abs(n);

    var s = "";

    for (var i = 0; i < fraction.length; i++) {
      s += (
        digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
      ).replace(/零./, "");
    }
    s = s || "整";
    n = Math.floor(n);

    for (var i = 0; i < unit[0].length && n > 0; i++) {
      var p = "";
      for (var j = 0; j < unit[1].length && n > 0; j++) {
        p = digit[n % 10] + unit[1][j] + p;
        n = Math.floor(n / 10);
      }
      s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
    }
    // return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
    return s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整");
  },
  // 验证身份证号
    checkId(idCard) {
        var idCardWi = new Array(
            7,
            9,
            10,
            5,
            8,
            4,
            2,
            1,
            6,
            3,
            7,
            9,
            10,
            5,
            8,
            4,
            2
        ); //将前17位加权因子保存在数组里
        var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
        var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
        for (var i = 0; i < 17; i++) {
            idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
        }

        var idCardMod = idCardWiSum % 11; //计算出校验码所在数组的位置
        var idCardLast = idCard.substring(17); //得到最后一位身份证号码

        //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
        if (idCardMod == 2) {
            if (idCardLast == "X" || idCardLast == "x") {
                return true;
            } else {
                return false;
            }
        } else {
            //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
            if (idCardLast == idCardY[idCardMod]) {
                return true;
            } else {
                return false;
            }
        }
  },
  checkRegorg(regorgCn) {
    if (regorgCn.includes("奎文")) {
        return '370705'
    }
    if (regorgCn.includes("滨海")) {
        return '370727'
    }
    if (regorgCn.includes("潍城")) {
        return '370702'
    }
    if (regorgCn.includes("寒亭")) {
        return '370703'
    }
    if (regorgCn.includes("安丘")) {
        return '370784'
    }
    if (regorgCn.includes("临朐")) {
        return '370724'
    }
    return '370700'
  }
  
};
export default fun;
