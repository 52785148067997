import request from "@/network/request";
function get(url, data, header, type, clear = false) {
  return request({
    url: url,
    method: "GET",
    data: data,
    headers: header,
    type,
    clear,
  });
}

function post(url, data, header, type, clear = false) {
  return request({
    url: url,
    method: "POST",
    data: data,
    headers: header,
    type,
    clear,
  });
}
var api = {
  // 发送短信验证码
  Lwwan_sendCode: (data) => post(`/sendmessage/sendCode`,data, {}, "codemail"),
  // 验证码验证信息
  Lwwan_checkSend: (data) => post(`/sendmessage/checkSend`,data, {}, "codemail"),
  // 获取小程序二维码
  // getWxCode_WXEwmSeismic: (type) => post('/getWxCode/WXKZEwm', '', '', type),
  // 用户信息填写获取二维码
  // agent_getQrInfo: (type) => post('/agent/getQrInfo', '', '', type),
  // 获取用户扫脸信息
  pass_receiveinfo: (data) => post(`/receiveinfo/${data}`, "", "", "", true),
  // 查询人脸照片是否上传
  // pass_QuakePdf: data => get(`/QuakePdf/${data}`),
  // 获取法人照片
  // pdf_list: data => get('/pdf/list', data),
  // 签名上传
  // earthquake_saveSignature: data => post('/earthquake/saveSignature', data),
  // 邮递信息
  // mail_add: data => post('/mail/add', data),
  // 提交表单
  // earthquake_addQuark: data => post('/earthquake/addQuark', data),
  // 发送短信
  // license_sendKWKZSms: (data, type) => post('/license/sendKWKZSms', data, '', type),
  // 打印记录
  // license_savePrintRecord: data => post('/license/savePrintRecord', data),
  // 查询打印记录列表
  // mail_printRecordList: data => post('/mail/printRecordList', data),
  // 表单详情
  // earthquake_getQuakeByUniscid: data => get(`/earthquake/getQuakeByUniscid`, data),
  // earthquake_getList: data => get(`/earthquake/getList`, data),
  // 项目编号查询
  // GGProject_findByXmbh: data => post(`/GGProject/findByXmbh/${data}`),
  // -------------------------------------通用接口-----------------------------------------------
  //   保存打印记录 flag
  //   1 人力资源
  //   2 公共卫生
  //   3 出版物
  //   4 劳务派遣
  //   5 放射
  // 通用接口  选择企业信息 保存企业信息表
  // baseinformation_add: (data, regorg, useto) => post(`/baseinformation/${regorg}/${useto}/add`, data, {}, 'aqggws'),
  baseinformation_add: (data, regorg, useto) =>
    post(`/baseinformation/${regorg}/${useto}/add`, data),
  // 存纸值
  Selfservice: (data) =>
    post("/Selfservice", data, {}),
  // 查询
  Selfservice_getByMac: (data) =>
    get("/Selfservice/getByMac", data, {},'',true),
  
  // 通用接口  法人查询企业信息
  baseinformation: (data) =>
    get("/baseinformation/getInfoList", data, {}, "BH"),
  // 通用接口  代理人二维码
  agent_getQrInfo: (data) => post("/agent/getQrInfo", data, {}, "codeUrl"),
  // 劳务派遣变更  获取代理人授权信息
  agent_receiveinfo: (data) => post(`/receiveinfo/${data}`, "", {}, "BH"),
  // 通用接口  上传签名
  // common_saveSignature: data => post(`/license/saveSignature`, data, {}, 'aqggws'),
  common_saveSignature: (data) => post(`/license/saveSignature`, data),
  // 通用接口  上传照片
  common_upfiles: (data, formData,scope) =>
    post(`/industryFile/upfiles?type=1&pid=${data}&scope=${scope}`, formData),
  // 通用接口  获取上传列表
  common_getVantlistByClass: (data) =>
    get(`/industryFile/getVantlistByClass?pid=${data}`),
  // 通用接口  删除文件
  common_delfile: (data) => post(`/industryFile/delfile?id=${data}`),
  // 奎文缺纸短信提醒
  sendKWSms: (data) => post(`/license/sendKWSms`, data, {}, "mail"),
  // 滨海缺纸短信提醒
  sendBHSms: (data) => post(`/license/sendBHSms`, data, {}, "mail"),
  // 设置纸张
  Selfservice: (data) => post(`/Selfservice`, data),
  // 用id查询提交的企业的信息
  baseinformation_firm: (data) => post(`/baseinformation/${data}`),
  // 通用 查询业务办理进度
  common_check: (useto, uniscid) => post(`/license/check/${useto}/${uniscid}`),
  // 通用接口 获取微信小程序二维码
  common_getWxCode: (uuid, url) => get(`/getWxCode/getWeAppEwm/${uuid}?Url=${url}`, '', {}, "codeUrl"),
  // common_getWxCode: (uuid, url) => get(`/getWxCode/getWeAppEwmTest/${uuid}?Url=${url}`, '', {}, "codeUrl"),
  // 快递邮寄
  express_delivery: (to, data) => post(`/${to}/postAdd`, data),
     // 查看邮寄信息/状态 fpoststatus =1
    mail_info: (data) => get(`/mail/${data}`),
  // -------------------------------------劳务派遣变更-----------------------------------------------
  // 劳务派遣变更  提交表单
  laborform_addLabor: (data) => post("/laborform/addLabor", data),
  // 劳务派遣变更  获取企业变更前信息(用户查询)
  queryMaxInfoByuniscid: (data) =>
    post("/laborform/queryMaxInfoByuniscid", data, {}, "", true),
  // 劳务派遣变更  获取小程序二维码
  getWxCode_getRLEwm: (data) =>
    get(`/getWxCode/getRLEwm/${data}`, "", {}, "mail"),
  // 劳务派遣变更  查看人脸是否上传完成
  getWxCode_laborPdf: (data) => get(`/laborPdf/${data}`),
  // 劳务派遣变更  保存签名
  // laborform_saveSignature: data => post('/laborform/saveSignature', data),
  // 劳务派遣变更  上传文件
  // industryFile_upfiles: (data, formData) => post(`/industryFile/upfiles?type=1&pid=${data}`, formData),
  // 劳务派遣变更  删除文件
  // industryFile_delfile: data => post(`/industryFile/delfile?id=${data}`),
  // 劳务派遣变更  获取上传文件列表
  // industryFile_getVantlistByClass: data => get(`/industryFile/getVantlistByClass?pid=${data}`),
  // 劳务派遣变更  生成pdf(签章)
  laborform_createPdf: (data) => post(`/laborform/createPdf/${data}`),
  // 劳务派遣变更  劳务派遣变更详情
  laborform_laborform: (data) => get(`/laborform/${data}`),
  // 劳务派遣变更  生成许可证图片
  laborform_createImg: (data) =>
    post(`/laborform/createImg/${data}`, "", {}, "", true),
  // 劳务派遣变更  发送短信(安丘暂无短信)
  license_sendKWTestSms: (data) =>
    post("/license/sendKWLWSms", data, {}, "mail"),

  // -------------------------------------安丘公共卫生-----------------------------------------------

  // 安丘公共卫生  法人--输入身份证查询企业接口
  aqssws_getInfoList: (data) =>
    post(`/baseinformation/getInfoList?cerno=${data}`),
  // 安丘公共卫生  获取经营范围及对应的营业项
  aqssws_getLicenseScope: (data) => post(`/license/getLicenseScope/${data}`),
  // 安丘公共卫生  许可经营范围注意事项
  aqssws_informList: (data) => get("/inform/list", data),
  // 安丘公共卫生  填写证照表单
  aqssws_license: (data) => post("/license", data),
  // 安丘公共卫生  微信二维码
  aqssws_licenseRLEwm: (data) =>
    get(`/getWxCode/licenseRLEwm/${data}`, "", {}, "mail"),
  // 安丘公共卫生  检查人脸是否上传
  aqssws_checkFace: (data) => post(`/pdf/${data}`),
  // 公共卫生  生成PDF
  aqssws_createAndUploadPdf: (data) => post(`/pdf/createAndUploadPdf/${data}`),
  // 公共卫生  生成营业执照图片接口
  aqssws_createAndUploadImage: (data) => post(`/pdf/createAndUploadImage/${data}`),
  // 安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘安丘
  // 安丘公共卫生  生成PDF
  AQWS_createAndUploadPdfNew: (data) => post(`/pdf/createAndUploadPdfNew/${data}`),
  // 安丘公共卫生  生成营业执照图片接口
   AQWS_createAndUploadImageNew: (data) => post(`/pdf/createAndUploadImageNew/${data}`),
  // 安丘公共卫生  根据id获取证照信息
  aqssws_licenseDeatils: (data) => post(`/license/${data}`),
  // 临朐公共卫生查询
  aqssws_LQDetail: (data) => post(`/license/LQDetail/${data}`),
  // 上传户型图
  aqssws_updateByIdGetHxt: (data) => get(`/pdf/updateByIdGetHxt`, data),
  // 根据id获取户型图
  aqssws_selectHouseholdTypeListt: (data) => get(`/householdType/selectHouseholdTypeList`,data),
  // 安丘公共卫生  根据 用途 组织机构代码获取证照信息(查询详情用)
  aqssws_checkInfo: (data, useto) => post(`/license/${useto}/${data}`),
  // 公共卫生打证查询加密
  aqssws_queryInfoByuniscid: (uniscid, useto,currentTime) => post(`/license/queryInfoByuniscid/${useto}/${uniscid}/${currentTime}`),
  // 安丘公共卫生  发送短信
  // aqssws_sendAQMail: (data) => post(`/license/sendAQMail`, data, {}, "mail"),
  // 奎文短信
  ggws_sendKWMail: (data) => post(`/license/sendKWMail`, data, {}, "mail"),
  // 奎文缺纸短信
  ggws_sendKWSms: (data) => post(`/license/sendKWSms`, data, {}, "mail"),
  // 滨海短信
  ggws_sendBHMail: (data) => post(`/license/sendBHMail`, data, {}, "mail"),
  // 滨海缺纸短信
  ggws_sendBHSms: (data) => post(`/license/sendBHSms`, data, {}, "mail"),
  // 临朐发送申请表短信
  ggws_sendlQMail: (data) => post(`/sendmessage/sendClMessage?phone=15263688201,16653673081&content=${data}`, '', {}, "codemail"),
  // 公共卫生打印记录?
  license_savePrintRecord: (data) => post("/license/savePrintRecord", data),
  // 安丘公共卫生  查询公共卫生许可证信息
  // aqssws_queryInfoByuniscid: data => get(`/license/queryInfoByuniscid?uniscid=${data}`,'',{},'aqggws'),
  // 寒亭公共卫生短信
  ggws_sendHTMail: (data) => post(`/license/sendHTMail`, data, {}, "mail"),

  // -------------------------------------人力资源-----------------------------------------------

  // 人力资源  提交表单
  rlzy_addLabor: (data) => post("/hr/addHr", data),
  // 人力资源   签章
  rlzy_createPdf: (data) => post(`/hr/createPdf/${data}`),
  // 人力资源  人力资源变更详情
  rlzy_hr: (data) => get(`/hr/${data}`),
  // 人力资源  生成许可证图片
  rlzy_createImg: (data) => post(`/hr/createImg/${data}`, "", {}, "", true),
  // 人力资源  人力资源变更详情(统一社会信用代码)
  rlzy_queryInfo: (data) =>
    post("/hr/queryMaxInfoByuniscid", data, {}, "", true),
  // 人力资源  人力资源变更详情(统一社会信用代码)加密
  rlzy_queryMaxInfoByuniscidNew: (data) =>
    post("/hr/queryMaxInfoByuniscidNew", data, {}, "", true),
  // 人力资源变更打印记录
  rlzy_savePrintRecord: (data) => post("/hr/savePrintRecord", data),
  //人力资源变更发送短信
  rlzy_sendMail: (data) => post(`/license/sendKwRLMail`, data, {}, "mail"),
  // -------------------------------------人力资源服务业务备案-----------------------------------------------
  // 人力资源服务业务备案  提交表单
  rlba_addHrjob: (data) => post("/hrjob/addHrjob", data),
  // 人力资源   签章
  rlba_createPdf: (data) => post(`/hrjob/createPdf/${data}`),
  // 人力资源  人力资源变更详情
  rlba_hr: (data) => get(`/hrjob/${data}`),
  // 人力资源  生成许可证图片
  rlba_createImg: (data) => post(`/hrjob/createImg/${data}`, "", {}, "", true),
  // 人力资源  人力资源变更详情(统一社会信用代码)
  rlba_queryInfo: (data) =>
    post("/hrjob/queryMaxInfoByuniscid", data, {}, "", true),
    // 人力资源  人力资源变更详情(统一社会信用代码)加密
  rlba_queryMaxInfoByuniscidNew: (data) =>
    post("/hrjob/queryMaxInfoByuniscidNew", data, {}, "", true),
  // 人力资源备案打印记录
  rlba_savePrintRecord: (data) => post("/hrjob/savePrintRecord", data),
  // -------------------------------------出版物-----------------------------------------------
  // 出版物零售单位变更办事指南  提交表单
  cbw_addPublication: (data) => post("/publication/addPublication", data),
  // 出版物   签章
  cbw_createPdf: (data) => post(`/publication/createPdf/${data}`),
  // 出版物  出版物变更详情
  cbw_hr: (data) => get(`/publication/${data}`),
  // 出版物  生成许可证图片
  cbw_createImg: (data) =>
    post(`/publication/createImg/${data}`, "", {}, "", true),
  // 出版物  出版物变更详情(统一社会信用代码)
  cbw_queryInfo: (data) =>
    post("/publication/queryMaxInfoByuniscid", data, {}, "", true),
  // 出版物  出版物变更详情(统一社会信用代码)加密
  cbw_queryInfoByuniscidNew: (data) =>
    post("/publication/queryInfoByuniscidNew", data, {}, "", true),
  // 原法人扫脸二维码
  cbw_licenseRLEwm: (data) =>
    get(`/getWxCode/licenseOriginalEwm/${data}`, "", {}, "mail"),
  // 原法人人脸验证
  cbw_checkFace: (data) => post(`/PdfExtend/${data}`),
  // 出版物  获取企业变更前信息(用户查询)
  publication_bg: (data) =>
    post("/publication/queryMaxInfoByuniscid", data, {}, "", true),
  // 出版物打印记录
  publication_savePrintRecord: (data) =>
    post("/publication/savePrintRecord", data),
  //出版物发送短信
  publication_sendMail: (data) =>
    post(`/license/sendKwCBMail`, data, {}, "mail"),

  // -------------------------------------放射性-----------------------------------------------
  // 放射性  提交表单
  fsx_addForm: (data) => post("/radiation/addRadiation", data),
  // 放射性  生成许可证图片
  // fsx_createImg: data => post(`/publication/createImg/${data}`, '', {}, '', true),
  // 放射性   签章
  fsx_createPdf: (data) => post(`/radiation/createPdf/${data}`),
  // 放射性  放射性详情
  fsx_details: (data) => get(`/radiation/${data}`),
  // 放射性  放射性详情(统一社会信用代码)
  fsx_queryInfo: (data) =>
    post("/radiation/queryMaxInfoByuniscid", data, {}, "", true),
  // 放射加密查询
   fsx_queryMaxInfoByuniscidNew: (data) =>
    post("/radiation/queryMaxInfoByuniscidNew", data, {}, "", true),
  // 奎文放射pdfbase64(政府章印)
  fsx_creatPFBase64: (data) =>
    post(`/radiation/creatPFBase64/${data}`, "", {}, "pdf", true),
  // http://112.243.253.8:8081/license-api/enjoy/pass/radiation/creatTYPFBase64/{id}
  fsx_wcPFBase64: (data) =>
    post(`/radiation/creatTYPFBase64/${data}`, "", {}, "pdf", true),
  // -------------------------------------奎文公共卫生-----------------------------------------------

  // 奎文公共卫生  查询
  license_QueryByuniscid: (data) =>
    get(`/license/QueryByuniscid?Uniscid=${data}`, "", {}, "kw"),
  
};
export default api;
