import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.array.push.js";
export default {
  name: 'CodeCheck',
  props: {
    path: {
      type: String
    }
  },
  data() {
    return {
      cerno: "",
      //身份证
      phone: "",
      //手机号
      smsCode: "",
      //验证码
      smsDisable: false,
      //验证码发送
      checked: false,
      qrcodeShow: false,
      //二维码显示
      qrcode: "",
      timer: null,
      count: 300
    };
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    sendSMSCode() {
      if (this.phone.length < 11) {
        _Toast.fail("请输入手机号");
        return;
      }
      // 验证手机号码
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        _Toast.fail("请检查手机号");
        return;
      }
      this.$rqt.Lwwan_sendCode({
        phone: this.phone,
        regorg: localStorage.getItem("regorg")
      }).then(res => {
        _Toast(res.msg);
        if (res.code == 200) {
          this.smsDisable = true;
          this.timer = setInterval(() => {
            this.count--;
            if (this.count == 0) {
              clearInterval(this.timer);
              this.count = 300;
              this.smsDisable = false;
            }
          }, 1000);
        }
      });
    },
    checkSend() {
      if (this.phone.length < 11) {
        _Toast.fail("请输入手机号");
        return;
      }
      // 验证手机号码
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        _Toast.fail("请检查手机号");
        return;
      }
      if (this.cerno.length < 18) {
        _Toast.fail("请输入身份证号");
        return;
      }
      if (!this.$fun.checkId(this.cerno)) {
        _Toast.fail("身份证号错误");
        return;
      }
      if (this.smsCode.length != 6) {
        _Toast.fail("请输入验证码");
        return;
      }
      this.$rqt.Lwwan_checkSend({
        phone: this.phone,
        code: this.smsCode,
        cerno: this.$doSm4(this.cerno)
      }).then(res => {
        // console.log(res.data.token);
        if (res.data.token) {
          this.goNext(res.data.token);
        } else {
          _Toast.fail('验证码错误');
        }
      });
    },
    goNext(token) {
      var regorg = localStorage.getItem("regorg");
      var useto = localStorage.getItem("useto");
      this.$rqt.baseinformation_add({
        id: token,
        cerno: this.cerno,
        regorg: localStorage.getItem("regorg")
      }, regorg, useto).then(res => {
        this.$rqt.common_getWxCode(res.data.id, 'pagesAnst/licenseNew/licenseNew').then(res => {
          // this.$rqt.common_getWxCode(res.data.id, 'pagesAnst/licenceEXE/licenceEXE').then(res => {
          // console.log('微信小程序二维码', res);
          this.qrcode = res;
          this.qrcodeShow = true;
        });
        localStorage.setItem("uniqueId", res.data.id);
      });
    },
    renzheng() {
      this.$rqt.aqssws_checkFace(localStorage.getItem("uniqueId")).then(res => {
        // console.log('拿到的信息', res);
        if (res.data) {
          this.$router.push({
            path: this.path,
            query: {
              type: '1'
            }
          });
        } else {
          _Toast.fail("认证失败");
        }
      });
    },
    checkGuiZe() {
      this.$emit('checkGuiZe');
    }
  }
};