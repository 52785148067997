export default {
  name: 'Ems',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      //
      ems: {}
    };
  },
  created() {
    //
  },
  mounted() {
    //
    this.checkMail();
  },
  methods: {
    // 邮递信息
    checkMail() {
      this.$rqt.mail_info(this.id).then(res => {
        console.log(res);
        this.ems = res.data;
      });
    }
  }
};