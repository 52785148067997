import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "@/network/api.js";
import base from "@/network/base.js";
import { pdfUrl } from "./network/base.js";
import fun from "@/assets/js/fun";
import { vant } from "@/config/vant.config.js";
// import vant from "vant";
import "vant/lib/index.css";
import vueEsign from "vue-esign";
import title from "./components/formTitle.vue";
import CodeCheck from "./components/CodeCheck.vue";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import Ems from "./components/Ems.vue";


// px2rem 自适应
import "lib-flexible/flexible.js";
import 'whatwg-fetch'
import { doSm4, dptSm4 } from './assets/js/smKey'
// import { readConfig } from '@/assets/js/getBaseUrl.js'

// let applicationType = 'website' // 如果最后打包的是网站，就打开这个
// let applicationType = "exe"  // 如果最后打包的是exe应用，就打开这个
// if (applicationType === 'exe') {
  // (async function () {
  //   const res = await readConfig()
  //   console.log(res);
  //   window.$config = res
  // })()
// }
// 因为我原来的项目是可以打包成网站与桌面应用，但是在网页版中，window.require('electron')返回的是undefined，所以这里才会用applicationType来区分，如果打包后的是exe应用时，才去读取安装目录下的config.json文件


const app = createApp(App);
app.use(ElementPlus, { locale });
app.use(vant);
app.use(vueEsign);
app.use(store);
app.use(router);
app.config.globalProperties.$rqt = api;
app.config.globalProperties.$base = base;
app.config.globalProperties.$fun = fun;
app.config.globalProperties.$pdfUrl = pdfUrl;
app.config.globalProperties.$doSm4 = doSm4;
app.config.globalProperties.$dptSm4 = dptSm4;
app.component("formTitle", title);
app.component("CodeCheck", CodeCheck);
app.component("Ems", Ems);

app.mount("#app");
