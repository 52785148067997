import { createStore } from "vuex";

export default createStore({
  state: {
    baseinfo: {}, //企业信息
  },
  getters: {},
  mutations: {
    setBaseinfo(state, val) {
      state.baseinfo = val;
    },
  },
  actions: {},
  modules: {},
});
