// var key='WFZWFWaishandong'
// var key = '57465a57465761697368616e646f6e67'//16进制
// 字符串转16进制
// stringToHex(str) {
//     var val = "";
//     for (var i = 0; i < str.length; i++) {
//         if (val == "")
//             val = str.charCodeAt(i).toString(16);
//         else
//             val += str.charCodeAt(i).toString(16);
//     }
//     console.log("16进制", val);
//     return val;
// },
// 加密
function doSm4(msg) {
    const sm4 = require('sm-crypto').sm4
    // const msg = 'hello world! 我是 juneandgreen.' // 可以为 utf8 串或字节数组
    // const msg = el // 可以为 utf8 串或字节数组
    const key = "57465a57465761697368616e646f6e67" // 可以为 16 进制串或字节数组，要求为 128 比特
    let encryptData = sm4.encrypt(msg, key) // 加密，默认输出 16 进制字符串，默认使用 pkcs#7 填充（传 pkcs#5 也会走 pkcs#7 填充）
    // console.log("sm4", encryptData);
    return encryptData
}
// 解密
function dptSm4(msg) {
    const sm4 = require('sm-crypto').sm4
    const key = '57465a57465761697368616e646f6e67' // 可以为 16 进制串或字节数组，要求为 128 比特
    let decryptData = sm4.decrypt(msg, key) // 解密，默认输出 utf8 字符串，默认使用 pkcs#7 填充（传 pkcs#5 也会走 pkcs#7 填充）
    // console.log(decryptData);
    return decryptData
}

// export default doSm4
export {
    doSm4,
    dptSm4
}