import {
  Button,
  List,
  Cell,
  CellGroup,
  Picker,
  Popup,
  Popover,
  Field,
  ConfigProvider,
  CountDown,
  Form,
  Radio,
  RadioGroup,
  Overlay,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Switch,
  Area,
  Toast,
  ImagePreview,
  Icon,
  NoticeBar,
  Uploader,
  Loading,
} from "vant";

export function vant(app) {
  app.use(Button);
  app.use(List);
  app.use(Cell);
  app.use(CellGroup);
  app.use(Picker);
  app.use(Popup);
  app.use(Popover);
  app.use(Field);
  app.use(ConfigProvider);
  app.use(CountDown);
  app.use(Form);
  app.use(Radio);
  app.use(RadioGroup);
  app.use(Overlay);
  app.use(Checkbox);
  app.use(CheckboxGroup);
  app.use(Dialog);
  app.use(Switch);
  app.use(Area);
  app.use(Toast);
  app.use(ImagePreview);
  app.use(Icon);
  app.use(NoticeBar);
  app.use(Uploader);
  app.use(Loading);
}
